// icon
.menu-icon {
	display: none;

	@include respond(tablet) {
		display: inline-block;
		cursor: pointer;
		display: inline-block;
		padding: 28px 20px;
		position: relative;
		user-select: none;
	}
}

.menu-icon .navicon {
	background: #333;
	display: block;
	height: 3px;
	position: relative;
	transition: background 0.2s ease-out;
	width: 25px;
}

.menu-icon .navicon:before,
.menu-icon .navicon:after {
	background: #333;
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	transition: all 0.2s ease-out;
	width: 100%;
}

.menu-icon .navicon:before {
	top: 8px;
}

.menu-icon .navicon:after {
	top: -8px;
}

// menu btn
.menu-btn {
	// Hide the Checkbox
	display: none;
}

// Functionality
.menu-btn:checked ~ .navigation__list {
	// Do Transform Here
	opacity: 1;
	width: 100%;
}

.menu-btn:checked ~ .menu-icon .navicon {
	background: transparent;
}

.menu-btn:checked ~ .menu-icon .navicon:before {
	transform: rotate(-45deg);
}

.menu-btn:checked ~ .menu-icon .navicon:after {
	transform: rotate(45deg);
}

.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
	top: 0;
}
