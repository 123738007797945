.profile {
	padding: 2rem;

	&__flex {
		display: flex;
	}

	&__visual {
		flex: 0 0 30%;
		position: relative;

		&--figure {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&--image {
			// height: 4.5rem;
			width: 60%;
			height: auto;
			border-radius: 50%;
		}
		&--icon {
			width: 3rem;
			height: 3rem;
			fill: currentColor;
			position: absolute;
			right: 7rem;
			top: 14rem;
		}
	}
	&__content {
		flex: 0 0 70%;
	}

	&__form {
		width: 100%;
		padding: 2rem 0;
		display: flex;
		justify-content: center;
		flex-direction: column;
		// align-items: center;

		&--flex {
			display: flex;
			justify-content: space-between;

			& > div {
				flex: 0 0 48%;
			}
		}
		&--group {
			// width: 100%;

			input {
				font-size: 1.5rem;
				font-family: inherit;
				color: inherit;
				padding: 1rem 2rem;
				border-radius: 2px;
				background-color: var(--color-grey-light-2);
				border: none;
				width: 100%;
				display: block;
				transition: all 0.3s;

				&:focus {
					outline: none;
					background-color: var(--color-grey-light-3);
					box-shadow: 0 1rem 2rem rgba(var(--color-black), 0.1);
					border-bottom: 3px solid var(--color-primary);
				}

				&:focus:invalid {
					border-bottom: 3px solid var(--color-secondary);
				}

				&::-webkit-input-placeholder {
					color: var(--color-grey-dark-2);
				}
			}

			label {
				font-size: 1.4rem;
				font-weight: 700;
				// margin-left: 1rem;
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
				display: block;
				transition: all 0.3s;
			}

			input:placeholder-shown + label {
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}
