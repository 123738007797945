.createblog {
	min-height: 70vh;
	padding: 2rem 4rem;
	background: var(--color-white);
	// max-width: 60vw;

	&__form {
		display: flex;
		justify-content: center;
		flex-direction: column;

		position: relative;

		input,
		textarea {
			font-size: 1.5rem;
			font-family: inherit;
			color: inherit;
			padding: 1.5rem 2rem;
			// background-color: var(--color-grey-light-2);
			border: 1px solid #ddd;
			width: 100%;
			display: block;
			transition: all 0.3s;

			&:focus {
				outline: none;
				background-color: var(--color-grey-light-3);
				box-shadow: 0 1rem 2rem rgba(var(--color-black), 0.1);
				border-bottom: 3px solid var(--color-primary);
			}

			&:focus:invalid {
				border-bottom: 3px solid var(--color-secondary);
			}

			&::-webkit-input-placeholder {
				font-weight: 600;
				color: #aaa;
			}
		}

		label {
			font-size: 1.4rem;
			font-weight: 700;
			// margin-left: 1rem;
			// margin-top: 1rem;
			margin-bottom: 1rem;
			// display: block;
			transition: all 0.3s;
		}
	}
}

.fileInput[type='file'] {
	display: none;
}
.customFileInput {
	background: #ccc;
	border: 1px solid #ccc;
	padding: 6px 12px;
	cursor: pointer;

	display: flex;
	align-items: center;
	font-size: 2rem;

	&--icon {
		width: 2rem;
		height: 2rem;
		margin-right: 20px;
		fill: currentColor;
	}
}
