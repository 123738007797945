:root {
	// colors
	--color-primary: #49bf4c;

	--color-secondary: #9fd220;

	--color-tertiary: #282f6e;

	--color-white: #fff;
	--color-black: #000;

	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #ccc;

	--color-grey-dark-1: #767676;
	--color-grey-dark-2: #1a202c;

	--color-grey-text: #231f20;

	// color variants
	// #848C78
	// #5C6454
	// #090806
	// #1a202c
	// #44C462
	// #44C863
	// #39A759
	// #E2E8EE
	// #020A12
	// #ACB3B1
	// #F6DA9B
	// #4D5147
	// #A87D63

	// shadows
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

	// misc
	--line: 1px solid var(--color-grey-dark-1);

	// font
	--default-font-size: 1.6rem;
	--open-sans-font: 'Open Sans', sans-serif;
	--roboto-font: 'Roboto', sans-serif;

	// z-indexes
	--z-index-default: 1000;
}
