// ----------------------- base ---------------------------- //
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.5%; // 1rem = 10px
}

body {
	// padding: 0 1rem;
	overflow-x: hidden;
	overflow-y: hidden;
	// background-image: linear-gradient(
	// 	to bottom right,
	// 	rgba(#fff, 0.95),
	// 	rgba(#faf9f9, 0.6)
	// );
	// background-size: cover;
	// background-position: top;
}

::selection {
	background-color: var(--color-primary);
	color: var(--color-white);
}
