body {
	font-size: var(--default-font-size);
	font-family: var(--open-sans-font);

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.heading {
	&--main {
		font-family: var(--open-sans-font);
		font-weight: 900;
		line-height: 1.5;
		font-size: 3.5rem;
		color: #282f6e;
		// color: #4a82ff;
	}
	&--sub {
		font-family: var(--open-sans-font);
		font-weight: 700;
		line-height: 1.2;
		font-size: 2.5rem;
		color: #282f6e;
	}
	&--primary {
		font-family: var(--open-sans-font);
		font-weight: 700;
		font-size: 1.8rem;
		line-height: 1.6;
		color: #282f6e;
	}
	&--secondary {
	}
	&--tertiary {
	}
}

.text {
	&__content {
		font-family: var(--roboto-font);
		font-size: var(--default-font-size);

		&--black {
			color: var(--color-black);
		}
		&--grey {
			color: var(--color-grey-text);
			line-height: 2;
		}
		&--white {
			color: var(--color-white);
		}
	}
}
