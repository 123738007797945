.form-alert {
	&--div {
		// background: yellowgreen;
		color: #721c24;
		background: #f8d7da;
		// display: inline-block;
		padding: 1.2rem 2rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
	}
	&--message {
		// color: red;
		font-size: 1.6rem;
	}
}
