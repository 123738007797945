.dashboard {
	display: flex;
	// padding: 5rem 2rem;

	.main {
		padding: 2rem;
		flex: 1;
		// background-color: #f2f3f7;
		min-height: calc(100vh - 8rem);
		max-height: calc(100vh - 8rem);
		background-color: #dae0e6;
		overflow-y: scroll;
	}
}
