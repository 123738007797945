.users {
	padding: 2rem;
	&__table {
		background: white;
		// & > :not(:last-child) {
		// 	margin-bottom: 1rem;
		// }
	}

	&__flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.user__card {
	padding: 0.5rem 1rem;
	box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);

	&--flex {
		&-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
		&-col {
			display: flex;

			flex-direction: column;
		}
	}

	&--profile {
		display: flex;
	}

	&--figure {
		flex: 0 0 10%;
		display: flex;
		align-items: center;
	}

	&--info {
		flex: 0 0 90%;

		margin-left: 4rem;
		display: flex;
		padding: 2rem 0;
	}

	&--image {
		width: 90%;
		height: auto;
		border-radius: 50%;
	}

	&--icon {
		&-info {
			width: 2rem;
			height: 1.5rem;
			margin-right: 0.8rem;
			fill: currentColor;
		}

		&-action {
			width: 3rem;
			height: 3rem;
			fill: currentColor;

			cursor: pointer;
		}
		// position: absolute;
		// right: 7rem;
		// top: 14rem;
	}
}
