@import '../../scss/abstracts/mixins';

.sidenav {
	flex: 0 0 18%;
	background-color: #ffffff;

	&__list {
		list-style: none;
		margin-top: 3rem;

		&--item {
			box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
			color: var(--color-grey-text);
			text-transform: capitalize;
			position: relative;
			z-index: 10;

			&:not(:last-child) {
				margin-bottom: 5px;
			}

			.sidenav--active {
				background: var(--color-primary);
				color: var(--color-white);
			}
		}
		&--link {
			@include ResetLink();

			padding: 15px 30px;

			display: flex;
			align-items: center;

			&:hover {
				background: var(--color-primary);
				color: var(--color-white);
			}
		}
		&--icon {
			width: 2rem;
			height: 2rem;
			margin-right: 20px;
			fill: currentColor;

			// @include respond(tablet) {
			// 	width: 15px;
			// 	height: 15px;
			// 	margin-right: 10px;
			// }

			// @include respond(mobile-large) {
			// 	width: 10px;
			// 	height: 10px;
			// 	margin-right: 10px;
			// }
		}
	}
}
